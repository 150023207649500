<template>
  <div class="user">
    <div class="title">{{editFlag ?'编辑项目':'新增项目'}}</div>
    <div class="item_content">
      <el-form :model="queryInfo" label-width="120px" ref="form" :rules="rules">
        <el-form-item label="项目名称:" prop="projectName">
          <el-input v-model="queryInfo.projectName" placeholder="请输入项目名称" clearable style="width:650px"/>
        </el-form-item> 
        <el-form-item label="参与会员:" prop="participationMember">
          <el-select v-model="queryInfo.participationMember" placeholder="请选择" :multiple="true" style="width:650px">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="居间人:" prop="intermediary">
           <el-select v-model="queryInfo.intermediary" placeholder="请选择" :multiple="true" style="width:650px">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运营专员:" prop="userName">
         <span>{{this.queryInfo.userName}}</span>
        </el-form-item>
        <el-form-item label="项目地点:" prop="province">
          <my-cascader-area  
          ref="child"
          :country.sync="queryInfo.country"
          :province.sync="queryInfo.province"
          :city.sync="queryInfo.city"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"/>
        </el-form-item>
        <el-form-item label="项目简介:" prop="projectIntro">
          <el-input v-model="queryInfo.projectIntro" placeholder="请输入信息名称" type="textarea" style="width:650px;" :autosize="{ minRows: 4, maxRows: 6}"/>
        </el-form-item>
      </el-form>
      <div class="btn_area">
        <div class="btn" @click="addOne('form')">保存</div>
        <div class="btn" @click="outPlace()" style="margin-left:20px">取消</div>
      </div>
    </div>
  </div>
</template>


<script>
import MyCascaderArea from '@/components/MyCascaderArea.vue'
import { getAllUser, addProject, getProjectList, editProject } from '@/api/object.js'
export default {
  components: {
    MyCascaderArea,
  },
  data () {
    return {
      queryInfo:{},
      imgUrlShow:[],
      imageUrlList:[],
      userName:'',
      editFlag:false,
      imgList:[],
      options: [],
        value: '',
        rules:{
          projectName:[{ required: true, message: '请输入项目名称', trigger: 'blur' }],
          participationMember:[{ required: true, message: '请输入参与会员', trigger: 'change' }],
          intermediary:[{ required: true, message: '请输入居间人', trigger: 'change' }],
          userName:[{ required: true, message: '请输入运营专员', trigger: 'blur' }],
          province:[{ required: true, message: '请输入项目地点', trigger: 'change' }],
          projectIntro:[{ required: true, message: '请输入项目简介', trigger: 'blur' }]
        }
    }
  },
  mounted(){
    this.getAllUser()
    this.queryInfo.userName = sessionStorage.getItem('userName')
    if(this.$route.query.id){
      this.editFlag = true
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    getArea({ province, city }) {
      this.queryInfo.province = province
      this.queryInfo.city = city
    },
    async getDetail(id){
      const { data: res } = await getProjectList({
        id:id,
        current: 0,
        size: 10,
      })
      if(res.data.list) this.queryInfo = res.data.list[0]
      this.queryInfo.participationMember = this.queryInfo.participationMember.split(',')
      this.queryInfo.intermediary = this.queryInfo.intermediary.split(',')
    },
    changID(list){
      for(let i = 0; i<list.length; i++){
        for(let j = 0; j<this.options.length; j++){
          if(list[i] == this.options[j].userName){
            list[i] = this.options[j].id
          }
        }
      }
    },
    addOne(formName){
      if(this.editFlag){
        this.changID(this.queryInfo.participationMember)
        this.changID(this.queryInfo.intermediary)
      }
      this.queryInfo.participationMember = this.queryInfo.participationMember.join(',')
      this.queryInfo.intermediary = this.queryInfo.intermediary.join(',')
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(this.editFlag){
            const { data: res } = await editProject(this.queryInfo)
            if (res.resultCode === 200) {
              this.$message.success('修改成功!')
              this.outPlace()
            }
          }else{
            this.queryInfo.projectState = '待开始'
            const { data: res } = await addProject(this.queryInfo)
            if (res.resultCode === 200) {
              this.$message.success('添加成功!')
              this.outPlace()
            }
          }
        } else {
          this.$message.error('请填写必要的选项!')
          return false;
        }
      });
    },
    async getAllUser(){
      const { data: res } = await getAllUser()
      this.options = res.data
    },
    outPlace(){
      this.queryInfo = {}
      this.$router.push({
        path: `/businessManagement/objectManagement`,
      })
    },
    searchCountry(val) {
      this.queryInfo.country = val
    },
    searchProvince(val) {
      this.queryInfo.province = val
    },
    searchCity(val) {
      this.queryInfo.city = val
    },
  },
}
</script> 

<style scoped lang="less">
.user{
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  border-radius: 12px;
  background: #ffffff;
  .item_content{
    margin: 20px 0px 0px 20px;
  }
  .title{
    width: 100%;
    height: 40px;
    text-indent: 1.5em;
    border-bottom:1px solid #EFEFEF;
    margin: 16px 20px 0px 20px;
    font-size: 18px;
    line-height: 25px;
    color: #968DE2;
  }
}
.ImgArea{
  display: flex;
  position: relative;
  .cancel{
    cursor: pointer;
    position: absolute;
    font-size: 18px;
    top: -5px;
    right: 5px;
  }
}
.btn_area{
    margin: 150px 0 100px 60px;
    width: 100%;
    display: flex;
    .btn{
      color: #fff;
      text-align: center;
      line-height: 40px;
      width: 136px;
      height: 40px;
      background: #7C71E1;
      border-radius: 4px;
      cursor: pointer;
    }
  }
</style>
